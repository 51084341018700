@font-face {
  font-family: 'Roboto-Medium';
  src: url('assets/fonts/Roboto-Medium.otf') format('opentype'), url('assets/fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --primary-color: #f5a623;
  --secondary-color: #f5a623;
  --text-color: #f5a623;
  --text-color-active: #ffffff;
  --customer-font-family: 'Roboto';
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* > {
  outline: none;
}

// scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 6px;
  height: 176px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

// area mat group configurator

.mat-tab-header {
  width: 100%;
  height: 47px;
  box-shadow: 0 2px 12px #ecf0f5;
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;

  .mat-tab-labels {
    display: flex;
    justify-content: space-around;
  }

  .mat-tab-label {
    min-width: 50px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    letter-spacing: -0.1px;
  }

  .mat-tab-label-active {
    color: var(--primary-color);
    opacity: 1;
  }

  .mat-ink-bar {
    background-color: var(--primary-color) !important;
  }
}

mat-tab-body {
  //padding: 13px;
  box-sizing: border-box;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-tab-header {
  box-shadow: 0 2px 12px #ecf0f5;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  border: none;
}

.mat-tab-body-wrapper {
  box-shadow: 0 2px 12px #ecf0f5;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  height: 100%;
}

.mat-drawer.mat-drawer-side {
  z-index: 2;
  border: none;
}

.buttons-sidebar {
  display: inline-flex;
  //justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;

  .button {
    margin: 0;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.1px;
    background: white;
    max-width: max-content;
    border: none;
    width: max-content;
    height: 29px;
    border-radius: 15px !important;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-family: var(--customer-font-family);

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    &:active {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.7);
      transform: scale(0.97);
    }

    mat-icon {
      height: 19px;
      width: 19px;
    }
  }

  span {
    margin-left: 5px;
  }

  .button-active {
    border: 1px solid var(--primary-color);
    background: var(--primary-color) !important;
    color: white;
  }
}

.place-order-dialog {
  mat-dialog-container {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

span {
  font-family: var(--customer-font-family) !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.producer-name,
.product-name {
  color: #767676;
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.09px;
}

.product-name {
  color: var(--text-color);
}

.material-dialog .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
}

// alerts
.error-alert {
  border-radius: 0;
  background: white;
}

.success-alert {
  border-radius: 0;
  background: white;
}

/* 3D */
.model {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.webgl-content {
  position: static;
  height: 100%;
}

.webgl-content #gameContainer {
  position: absolute;
  background: #ffffff !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.webgl-content #gameContainer canvas {
  display: block;
  height: 100%;
  width: 100%;
}

/* For UnityProgress */
.webgl-content * {
  border: 0;
  margin: 0;
  padding: 0;
}

.webgl-content {
  position: relative;
  display: block;
}

/*.webgl-content .logo, .progress {position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}*/
/*.webgl-content .logo {background: url('../progressLogo.Light.png') no-repeat center / contain; width: 80px; height: 80px;}*/
/*.webgl-content .progress {height: 18px; width: 141px; margin-top: 90px; box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.5) !important;}*/
/*.webgl-content .progress .empty {background: url('../progressEmpty.Light.png') no-repeat right / cover; float: right; width: 100%; height: 100%; display: inline-block;}*/
/*.webgl-content .progress .full {!* background: url('../progressFull.Light.png') no-repeat left / cover; float: left; *! width: 0%; height: 100%; display: inline-block;}*/
/**/
/*.webgl-content .logo.Dark {!* background-image: url('../progressLogo.Dark.png'); *! !* background-image: url('../Logo_placeholder.png'); *! background-image: url('../logo-black.png');}*/
/*.webgl-content .progress.Dark .empty {!* background-image: url('../progressEmpty.Dark.png'); *! background: white;}*/
/*.webgl-content .progress.Dark .full {!* background-image: url('../progressFull.Dark.png'); *! background: #f5a623;}*/
/**/
/*.webgl-content .footer {margin-top: 5px; height: 38px; line-height: 38px; font-family: Helvetica, Verdana, Arial, sans-serif; font-size: 18px;} */
/*.webgl-content .footer .webgl-logo, .title, .fullscreen {height: 100%; display: inline-block; background: transparent center no-repeat;} */
/*.webgl-content .footer .webgl-logo {background-image: url('../webgl-logo.png'); width: 204px; float: left;}*/
/*.webgl-content .footer .title {margin-right: 10px; float: right;}*/
/*.webgl-content .footer .fullscreen {background-image: url('../fullscreen.png'); width: 38px; float: right;}*/

.cookie-alert {
  .swal-text {
    width: 360px;
    text-align: center;
  }

  .swal-footer {
    text-align: center;

    .swal-button {
      background-color: orange !important;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.back-to-product {
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 12px #ecf0f5;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0 10px;
  margin: 10px 0;
  overflow: auto;
  position: relative;

  .back-img {
    cursor: pointer;
    outline: none;

    img {
      outline: none;
      margin-right: 16px;
    }
  }
}

.price-block {
  color: var(--primary-color);
}

.price-of-product {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button-active-icons {
  background: #ffffff;
  border: 1px solid #f5a623;

  svg {
    fill: orange !important;
  }

  span {
    color: #f5a623;
  }

  &:hover {
    background: orange;

    span {
      color: white;
    }

    svg {
      fill: white !important;
    }
  }
}

.general-plugin-class {
  .dimensions-block {
    .item {
      display: block !important;
      width: 100% !important;

      .left {
        display: flex !important;
        align-items: center !important;
        width: 100% !important;
      }
    }
  }

  .slide-nav__material-selector {
    flex-direction: column;

    .set-color-to-detail {
      width: 100%;
    }
  }
}

.mobile-version-album {
  .drawer {
    .mat-drawer {
      width: 350px !important;
      height: 100% !important;
    }

    .mat-drawer-content {
      height: 100% !important;
    }
  }

  .mat-drawer {
    width: 50% !important;
  }

  .search-block {
    height: 35px;

    mat-form-field {
      height: 35px;

      .mat-form-field-flex {
        height: 35px;
      }
    }
  }

  mat-tab-header {
    height: 36px;

    .mat-tab-labels {
      height: 36px !important;
    }
  }

  .panel-elements {
    height: calc(100% - 110px) !important;
  }

  .apply-buttons-container {
    height: 35px !important;

    .qty {
      height: 35px !important;
    }

    .apply-btn {
      height: 35px !important;
    }
  }

  .areas-holder {
    height: calc(100vh - 180px) !important;
  }

  .palette-buttons-block {
    top: 116px !important;
  }

  .expansion-panel-box {
    img {
      top: 7px;
    }
  }

  .clear-filter-button {
    top: 7px !important;
    height: 25px !important;
    width: 25px;
  }

  .mobile-version .panel-elements .mat-tab-labels,
  .mobile-version .panel-elements .mat-tab-label {
    margin-top: 0;
    height: 35px !important;

    .mat-tab-label-active {
      margin-top: 7px !important;
      height: 20px !important;
    }
  }
}

.landing-version {
  .areas-holder {
    height: calc(100vh - 215px) !important;
    overflow-y: auto;
  }

  .buttons-sidebar {
    display: flex;
    justify-content: center;
  }

  .panel-elements {
    height: calc(100% - 60px) !important;

    .sub-collection {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      height: max-content;
      justify-content: space-between;

      .collection-item {
        width: calc(50% - 10px) !important;

        .collection-item-body {
          div {
            width: 100%;
          }
        }

        .grey-bg {
          background: white !important;
          width: 100% !important;
          height: 100% !important;
          display: flex !important;
          justify-content: center !important;
          margin-right: 0 !important;

          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

.request-model-dialog {
  @media screen and (max-width: 550px) {
    max-width: 95vw !important;
    max-height: 98vh !important;
    form {
      padding: 0 20px !important;
    }
  }
}

.tutorial-modal {
  mat-dialog-container {
    height: 80vh;
    aspect-ratio: 12/9;
    border: none;
    box-shadow: none;
    padding: 0 !important;
  }

  app-tutorial-modal {
    background: white;
    height: 100%;

    > div {
      height: 100%;
    }
    .mat-stepper-horizontal-line {
      margin: 0;
      min-width: 12px;
    }
    .mat-horizontal-stepper-header .mat-step-icon {
      margin: 0 6px;
    }
    .tutorial-modal .mat-dialog-actions {
      margin-bottom: 21px;
    }
    .mat-horizontal-stepper-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
    }
    .mat-horizontal-content-container {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .mat-dialog-content p {
      margin-bottom: 21px;
    }
  }

  .mat-dialog-title,
  .mat-dialog-content,
  .mat-dialog-actions {
    padding: 0;
    margin: 0;
  }

  .mat-dialog-title {
    background: #f2f2f2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 18px;

    b {
      margin: 0 5px;
    }
  }

  .mat-dialog-content {
    height: calc(80vh - 100px);
    max-height: calc(80vh - 100px);

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      color: #393939;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.17px;

      &:first-letter {
        color: #f5a623;
      }
    }

    .mat-stepper-horizontal {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mat-horizontal-stepper-header-container {
      width: auto;
      padding-top: 18px;
    }

    .mat-horizontal-content-container {
      min-height: calc(80vh - 260px);
      height: max-content;
      padding: 0;

      .mat-horizontal-stepper-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5%;
        justify-content: center;

        &:first-child {
          img {
            width: auto;
            height: 70%;
            aspect-ratio: 2/1;
            margin-top: 21px;
            margin-bottom: 26px;
          }
        }

        img {
          width: 80%;
          height: 70%;
          object-fit: contain;
          margin-top: 21px;
          margin-bottom: 26px;
        }
      }
    }

    mat-horizontal-stepper {
      height: 100%;

      mat-step {
        img {
          height: 100%;
        }
      }
    }
  }

  .mat-dialog-actions {
    box-sizing: border-box;
    min-height: 50px;
    padding-bottom: 14px;
    display: flex;
    justify-content: center;
  }

  .mat-step-label {
    display: none !important;
  }

  .mat-step-header {
    min-width: 25px;
    outline: none !important;
    background: initial !important;
    height: 25px;
    padding: 0;

    .mat-step-icon-selected {
      background-color: orange;
    }
  }

  .next-btn {
    background: orange;
    color: white;
  }

  @media screen and (min-width: 550px) and (max-width: 1024px) {
    mat-dialog-container {
      min-height: 70vh;
      height: max-content;
    }

    .mat-horizontal-stepper-header-container {
      width: 47% !important;

      @media screen and (min-width: 550px) and (max-width: 900px) {
        width: 80% !important;
      }
    }

    .mat-dialog-content {
      min-height: calc(100% - 100px) !important;
      height: calc(100% - 100px) !important;
      max-height: calc(100% - 100px) !important;

      .mat-horizontal-content-container {
        height: max-content !important;
      }

      .mat-horizontal-content-container .mat-horizontal-stepper-content:first-child {
        img {
          width: 70% !important;
          @media screen and (min-width: 550px) and (max-width: 750px) {
            width: 80% !important;
          }
        }
      }
    }

    .mat-stepper-horizontal-line {
      min-width: 10px;
    }
    app-tutorial-modal {
      .mat-horizontal-content-container .mat-horizontal-stepper-content {
        img {
          width: 100% !important;
          height: 300px !important;
        }
      }
      .mat-dialog-content p {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.168823px;
      }
    }
  }

  @media screen and (min-width: 500px) and (max-width: 1200px) and (min-height: 500px) and (max-height: 1200px) and (min-aspect-ratio: 4/3) {
    mat-dialog-container {
      .mat-horizontal-stepper-header-container {
        width: 80% !important;
      }

      .mat-horizontal-stepper-content[aria-expanded='true'] {
        &:first-child img {
          width: 50% !important;
          margin: 0;
        }

        img {
          width: 50% !important;
        }
      }
    }
  }

  @media screen and (min-width: 250px) and (max-width: 900px) and (min-height: 250px) and (max-height: 900px) and (min-aspect-ratio: 2/1) {
    mat-dialog-container {
      height: 95vh;
      aspect-ratio: auto;

      .mat-dialog-content {
        p {
          font-size: 14px;
          line-height: 18px;
          width: 60%;
          padding-left: 10px;
          box-sizing: border-box;
          margin-bottom: 0;
        }
      }

      .mat-horizontal-stepper-header {
        height: 40px;
      }

      .mat-horizontal-content-container {
        .mat-horizontal-stepper-content[aria-expanded='true'] {
          flex-direction: row !important;
          min-width: 100%;
          box-sizing: border-box;

          &:first-child img {
            width: 40% !important;
            margin: 0;
          }

          img {
            width: 40% !important;
            height: auto !important;
            margin: 0 !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100vw !important;

    mat-dialog-container {
      height: 90vh;
    }
    app-tutorial-modal {
      .mat-horizontal-content-container {
        height: fit-content !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mat-horizontal-stepper-content[aria-expanded='false'] {
        height: 0 !important;
      }
      .mat-horizontal-content-container .mat-horizontal-stepper-content {
        img {
          height: 156px;
        }
      }
    }

    .mat-dialog-content {
      height: 90vh !important;
      max-height: calc(90vh - 100px);

      .mat-horizontal-stepper-header-container {
        width: 100%;
        display: flex;
        justify-content: center;

        mat-step-header {
          padding: 0;
        }
      }

      .mat-horizontal-content-container .mat-horizontal-stepper-content {
        img {
          width: 95% !important;
        }

        &:first-child img {
          width: 100% !important;
        }

        p {
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 0.168823px;
        }
      }
    }

    .mat-stepper-horizontal-line {
      min-width: 16px;
      max-width: 16px;
    }
  }

  @media screen and (max-device-width: 560px) and (max-device-height: 870px) and (orientation: landscape) {
    max-width: 90vw !important;
    width: 95vw !important;
    height: 95vh !important;

    .mat-dialog-title {
      height: 30px;
    }

    .mat-dialog-actions {
      min-height: 30px;
      padding-bottom: 5px !important;

      button {
        height: 30px;
        line-height: 30px;
      }
    }

    .mat-dialog-container {
      height: 95vh !important;
    }

    .mat-horizontal-content-container {
      height: calc(80vh - 75px) !important;
    }

    .mat-horizontal-stepper-header-container {
      width: 47% !important;
      height: 30px !important;

      .mat-horizontal-stepper-header {
        height: 30px !important;
      }
    }

    .mat-dialog-content {
      height: calc(95vh - 75px) !important;
      max-height: calc(95vh - 75px) !important;

      .mat-horizontal-stepper-content {
        display: flex !important;
        flex-direction: row !important;

        img {
          min-width: 33vw;
        }
      }

      img {
        margin: 0 !important;
        margin-right: 5px !important;
      }
    }
  }
}

.product-details-modal {
  height: max-content !important;
  min-height: 500px !important;
  width: 550px !important;

  .mat-dialog-title,
  .mat-dialog-content,
  .mat-dialog-actions {
    padding: 0;
    margin: 0;
  }

  .mat-dialog-title {
    background: #f2f2f2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 18px;
  }

  img {
    height: 250px;
  }

  .mat-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    min-height: 360px;

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: #393939;
      text-align: justify;

      &:first-letter {
        color: #f5a623;
      }
    }
  }

  mat-dialog-container {
    padding: 0 !important;
  }

  .mat-dialog-actions {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 550px) {
    max-width: 95vw !important;
  }

  @media screen and (max-device-width: 870px) and (orientation: landscape) {
    max-width: 90vw !important;
    width: 95vw !important;
    height: 95vh !important;
    min-height: 95vh !important;

    .mat-dialog-title {
      height: 30px;
    }

    .mat-dialog-actions {
      min-height: 30px;
      padding-bottom: 5px !important;

      button {
        height: 30px;
        line-height: 30px;
      }
    }

    .mat-dialog-container {
      height: 95vh !important;
    }

    .mat-horizontal-content-container {
      height: calc(80vh - 75px) !important;
    }

    .mat-horizontal-stepper-header-container {
      width: 47% !important;
      height: 30px !important;

      .mat-horizontal-stepper-header {
        height: 30px !important;
      }
    }

    .mat-dialog-content {
      height: calc(95vh - 150px) !important;
      max-height: calc(95vh - 150px) !important;
      min-height: 190px;
      flex-direction: row;
      padding: 0 !important;

      img {
        height: 165px;
      }

      .mat-horizontal-stepper-content {
        display: flex !important;
        flex-direction: row !important;

        img {
          min-width: 33vw;
        }
      }

      img {
        margin: 0 !important;
        margin-right: 5px !important;
      }
    }
  }
}

.plugin-class {
  .mat-drawer {
    width: 310px !important;
  }
}
